import { template as template_bf08f3cb2359475bbe3c80a1666c4170 } from "@ember/template-compiler";
const WelcomeHeader = template_bf08f3cb2359475bbe3c80a1666c4170(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
